.footer-link {
    /* text-decoration: none; */
    text-decoration:underline;
    color: #FFFFFF;
}

.footer {
    background-color: #3B3B3B;
}

.info {
    border-bottom: 1px solid #A8A8A8;
    width: 100%;
}

.footer-title,
.trademark {
    color: #A8A8A8;
}

.footer-text {
    color: #FFFFFF;
}

.button-podpora {
    width: 100px;
    height: 100px;
    background-color: transparent;
    border: none;
    border-radius: 50%;

    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
}

@media only screen and (min-width: 1200px) {

    .info2,
    .info {
        width: 1180px;
    }
}