.main_title {
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

.sub_title {
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

.help_text {
    font-size: 17.5px;
    font-style: italic;
    font-weight: 400;
    line-height: 26.25px;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

.text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.text--bold {
    font-weight: 600;
}

.margin_bottom-20 {
    margin-bottom: 20px;
}