.header-link-img {
    height: 24px;
    width: 24px;
}

.header-link-text {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 1em;
    line-height: 1.5em;
}

.fit-content-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 59px;
}