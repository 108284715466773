.background {
    background-color: #F5F5F5;
    /* margin-top: 155px; */
    position: relative;

    &.mobile {
        margin-top: 55px;
    }
}

input[type='checkbox']::before,
input[type='radio']::before {
    background-color: #2362A2;
    outline: #2362A2;
}

input[type='checkbox'],
input[type='radio'] {
    min-height: 1.25em;
    min-width: 1.25em;
    height: 1.25em;
    width: 1.25em;
    outline: #2362A2;
}

input[type='checkbox']:checked {
    background-color: #2362A2;
    outline: #2362A2;
}

input[type='checkbox']::after,
input[type='radio']::after {
    background-color: #2362A2;
    outline: #2362A2;
}

/* PRIDAT STAVBU */

.pridat-container {
    width: 875px;
    border: 1px solid tomato;
}

.pridat-header {
    background-color: #F5F5F5;
    padding: 40px 48px;
}

.pridat-title {
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #3B3B3B;

}

.pridat-text {
    font-size: 19px;
    font-weight: 600;
    line-height: 28.5px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #3B3B3B;
}

.tea-text {
    font-size: 21px;
    font-weight: 500;
    line-height: 31.5px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #3B3B3B;
}

.pridat-body {
    background-color: #F5F5F5;
    padding-left: 48px;
    padding-right: 48px;
}

.pridat-footer {
    background-color: #F5F5F5;
    padding: 40px 48px;
}

.pridat-button {
    width: 166px;
    height: 39px;
    background-color: transparent;
    border: none;
    border-radius: 3px;

    font-size: 13px;
    font-weight: 700;
    line-height: 22.75px;
    letter-spacing: 0.02em;
    text-align: left;

}

.plus-button {
    background-color: #B5D9F3;
    color: #FFFFFF;
}

.further-button {
    color: #66A1D8;
    background-color: #FFFFFF;
    border: 1px solid #B5D9F3;
}

.close-button {
    color: #2362A2;
    border: 1px solid #2362A2;
}

.info-text-header--italic {
    margin-top: .5rem;
    font-size: 1.1rem;
    font-style: italic;
}

.text-area--info-text {
    color: #686868;
    font-size: 14px;
    margin-top: 2.5rem;
    margin-bottom: .5rem;
}

.modal-input-style {
    font-weight: 400;
    font-size: 0.909rem;
    line-height: 1.464rem;
    letter-spacing: 0.014rem;
    color: #686868;
}

.construction-info-container {
    background-color: #FFF;
    padding: 1.5rem;
    border: solid #DDDDDD 1px;
    border-radius: 3px;
}

.construction-info-block {
    border-bottom: solid #DDD 1px;
    padding: .25rem 0;
}

.construction-info-text {
    font-weight: 400;
    font-size: 0.909rem;
    line-height: 1.464rem;
    letter-spacing: 0.014rem;
    color: #3B3B3B;
    padding-left: 1rem;
}

.construction-info-block-padding-top {
    padding-top: .25rem;
}