.vyjimka-title {
    font-weight: 500;
    font-size: 1.75em;
    line-height: 1.5em;
    letter-spacing: 0.01em;
}

.vyjimka-title-note {
    font-weight: 400;
    font-style: italic;
    font-size: 1.09375em;
    line-height: 1.5em;
    letter-spacing: 0.01em;
}

.vyjimka-input-text {
    font-weight: 400;
    font-size: 0.90625em;
    line-height: 1.6em;
    letter-spacing: 0.014em;
    color: #686868;
}

.vyjimka-input-text-link {
    font-weight: 400;
    font-size: 0.90625em;
    line-height: 1.6em;
    letter-spacing: 0.014em;
    padding: 0 !important;
    color: #2362A2;
    /* border-bottom: 1px #2362A2 solid; */
    text-decoration: underline;
}

.vyjimka-input-text-link:hover {
    cursor: pointer;
}

.vyjimka-input-help-text {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 22.75px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #686868;
}