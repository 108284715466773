.button-fill-in-container {
    width: 100%;
    /* max-width: 156.5px; */
}

.new-request-card-image {
    padding: 0;
    object-fit: cover;
}

.button-more-info {
    background-color: transparent;
    color: #2362A2;
    width: 100%;
    height: 39px;
    max-width: 156.5px;
    border-radius: 3px;
    border: solid #2362A2 1px;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
    text-wrap: nowrap;
}

.button-more-info:hover {
    background-color: var(--gov-color-primary-200);
}

.button-show-request-card {
    background-color: transparent;
    color: #2362A2;
    width: 100%;
    height: 40px;
    padding: 8px, 12px, 9px, 12px;
    border-radius: 3px;
    border: solid #2362A2 1px;
    gap: 12px;

    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.0125em;
}

.card-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0125em;
    text-align: left;
    margin-left: 24px;
    margin-bottom: 4px;
    position: relative;
}

.card-info::before {
    content: ".";
    position: absolute;
    top: -1.2rem;
    left: -1.6rem;
    font-size: 4.2rem;
    color: #2362A2;
}

.button-fill-in {
    background-color: #2362A2;
    margin-bottom: 0;
    color: white;
    width: 100%;
    height: 39px;
    /* max-width: 156.5px; */
    border-radius: 3px;
    border: solid #2362A2 1px;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
    text-wrap: nowrap;
}

.button-fill-in:hover {
    background-color: var(--gov-color-primary-700);
}

.card-info--lazy-loading {
    margin-left: 0px;
    margin-bottom: 4px;
}

.new-request-card-header {
    height: 100%;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border: solid #DDDDDD 1px;
}

.card-content {
    flex: 1;
}

.new-request-card-header-more-info {
    margin-bottom: 1rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0125em;
    text-align: left;
}

.new-request-card-header-title {
    position: absolute;
    width: auto;
    bottom: 10px;
    margin: 16px;
    font-size: 19px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #FFFFFF;
    word-break: normal;
    max-width: 300px;
    z-index: 3;
}

.category {
    position: relative;
    /* height: auto; Allows the element to grow based on content */
    min-height: 200px;
    width: 100%;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    overflow: hidden;
    transition: height 0.3s ease; /* Optional: smooth height transition */
}

.linear-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

@-moz-keyframes loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

@-webkit-keyframes loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

@-ms-keyframes loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

@-o-keyframes loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

.lazy-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 4;
    background: linear-gradient(to right, #f3f3f3 25%, #e0e0e0 50%, #f3f3f3 75%);
    background-size: 200% 100%;
    animation: loading 1.5s linear infinite;
}

.card-info--lazy-loading {
    color: transparent;
    position: relative;
}

.category-text {
    position: absolute;
    top: 16px;
    left: 16px;
    display: inline-flex;
    border-radius: 4px;
    background-color: #232323;
    max-width: 49px;
    font-size: 13px;
    font-weight: 700;
    line-height: 22.75px;
    letter-spacing: 0.02em;
    color: #D9EFFF;
    z-index: 3;
}

.list-items-container {
    padding: 0;
    width: 100%;
    position: relative;
}

@media only screen and (min-width: 768px) {
    .button-show-request-card {
        width: 378px;
    }
}

@media only screen and (max-width: 768px) {
    .gov-card__main {
        padding: 1em;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1050px) {

}