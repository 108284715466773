.upload-progress-container {
    margin-top: -0.6em;
}

.dropdown-input {
    background: url('../assets/arrowDownBlue.svg') no-repeat right .8rem center;
    appearance: none;
    padding: .5rem 1.5rem .5rem .8rem;
    background-color: white;
    border-radius: 3px;
    border: solid 1px #DDDDDD;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dropdown-input:disabled {
    background-color: #EFEFEF4D;
}

progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 2px;
    width: 100%;
    z-index: 1;
}

progress[value] {
    --color: #2362A2;   
    --background: #DDDDDD;
    background: var(--background);
}

progress[value]::-webkit-progress-bar {
background: var(--background);
}

progress[value]::-webkit-progress-value {
background: var(--color);
}

progress[value]::-moz-progress-bar {
background: var(--color);
}

@supports (-moz-appearance: none) {
    progress {
        height: 4px;
    }
}
