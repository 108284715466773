.provoz-subtitle {
    font-size: 19px;
    font-weight: 600;
    line-height: 28.5px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #3B3B3B;
}

.provoz-subtitle--help-text {
    font-size: 17.5px;
    font-style: italic;
    font-weight: 400;
    line-height: 26.25px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #3B3B3B;
}

.stavba-info__text {
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.422rem;
    letter-spacing: 0.015em;
    color: #2362A2;
}