.time-card {
    font-size: 14px
}

.zamery-main-container {
    max-width: 1180px;
}

.zamery-container {
    max-width: 377px;
    height: 100%;
    background-color: #FFFFFF;
}

.zamery-id {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.0125em;
    color: #2362A2
}

.zamery-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #686868;
}

.zamery-row-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.zamery-button {
    width: 100%;
    height: 32px;
    gap: 16px;
    border: 1px solid #2362A2;
    border-radius: 3px;
    background-color: transparent;
    color: #2362A2;

    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
}

.zamery-button--filled {
    background-color: #2362A2;
    color: #FFFFFF;
}

.zamery-button-dev {
    width: 156px;
    max-width: 50%;
    height: 32px;
    gap: 16px;
    border: none;
    border-radius: 3px;
    background-color: #2362A2;
    color: #FFFFFF;

    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
}

.zamery-button-dev--delete {
    background-color: transparent;
    border: 1px solid #C52A3A;
    color: #C52A3A;
}