.file-container.error {
    padding: 0 1em;
    border: 0.0625em solid #C52A3A !important;
}

.delete-btn-container {
    cursor: pointer;
}

.cancel-btn-container {
    cursor: pointer;
}

.retry-btn-container {
    cursor: pointer;

    &.disabled {
        cursor: default;
    }
}

.close-error-btn {
    cursor: pointer;
}

.delete-btn-text {
    color: #2362A2;
    font-size: 0.9375em;
    font-weight: 500;
    letter-spacing: 0.018em;
    text-align: right;
    width: fit-content;
}

.delete-btn-text.error {
    color: #C52A3A;
}

.file-description {
    color: #3B3B3B;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: 0.0125em;
    text-align: left;
    text-wrap: wrap;
}

.file-description.error {
    color: #C52A3A;
}

@media only screen and (max-width: 476px) {
    .file-description {
        max-width: 10em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}