.breadcrumbs-section-detail {
    margin-top: 108px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.header__ul li a, .header__li-rizeni-id {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125em;
}

.header__li-rizeni-id {
    font-weight: 700;
}

@media only screen and (min-width: 1040px) {
    .breadcrumbs-section-detail {
        margin-top: 155px;
    }
    
}