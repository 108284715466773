.card-img img {
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
    object-fit: cover;
}

.after-login-page-header-section {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 90px;
    width: 100%;
}

.after-login-main-section-row {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.after-login-freq-req-title {
    font-size: 23px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.01em;
    text-align: left;
}

.button-space {
    margin-right: 20px;
}

.button-show-request-card:hover {
    background-color: var(--gov-color-primary-200);
}

.button-create {
    width: 234px;
    border-radius: 3px;
    gap: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.0125em;
}

.button-home-page {
    background-color: #FFFFFF;
    border: 1px solid #2362A2;
    border-radius: 3px;
}

.uvodni-strana-link {
    width: 276px;
    height: 48px;
    border: 1px solid #2362A2;
    background-color: #2362A2;
    color: white;
    font-size: 1em;
    font-weight: 700;
}

.uvodni-strana-btn {
    width: 100%;
    height: 48px;
    border: 1px solid #2362A2;
    background-color: #2362A2;
    color: white;
    font-size: 1em;
    font-weight: 700;
}

.unauthorized-container {
    margin-top: 15em !important;
    margin-bottom: 3em !important;
}

.emoji-container {
    max-width: 350px;
    height: 200px;

}

.emoji {
    width: 100%;
}

.unauthorized-title {
    text-align: center;
}

.unauthorized-text {
    text-align: center;
}

.unauthorized-span {
    text-align: center;
}

.card-container {
    padding: 10px;
    overflow: visible;
}

.card-img img {
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
    object-fit: cover;
}

.card-content {
    width: 100%;
}

.error-page--img {
    width: 300px;
    margin-bottom: 2.5rem;
}

.error-page--header-text {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
}

.error-page--secondary-text {
    font-size: 1rem;
    text-align: center;
    line-height: 2rem;
    margin-bottom: 2.5rem;
    max-width: 700px;
    
    &.no-max-width {
        max-width: 100%;
    }

    &.no-margin {
        margin-bottom: 0.1rem;
    }
}

.item {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 290px;
    border: solid;
}

.salutation {
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.01em;
    color: #3B3B3B;

}

.welcome-to-portal {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.purpose-card {
    height: 120px;
}

.purpose-card:hover {
    border-color: #2362A2;
}

.purpose-card-id {
    font-size: 18px;
    color: #2362A2;
}

.purpose-button {
    height: 120px;
    font-size: 16px;
    color: #2362A2;
}

.purpose-button:hover {
    border-color: #2362A2;
}

.newest-event-container {
    border-bottom: 1px solid #A8A8A8;
}

.newest-event-button {
    background-color: transparent;
    width: 81px;
    height: 29px;
    border-radius: 3px;
    border: solid #2362A2 1px;
    color: #2362A2;
    gap: 12px;

    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
}

.event-filter-button {
    background-color: transparent;
    width: 74px;
    height: 26px;
    border-radius: 3px;
    border: none;
    color: #2362A2;
    gap: 12px;

    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
}

.active {
    background-color: #2362A2;
    color: #FFFFFF;
}

.event-section-button {
    background-color: transparent;
    width: 196.17px;
    height: 48px;
    border-radius: 3px;
    border: solid #2362A2 1px;
    color: #2362A2;
    gap: 16px;

    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
}

@media only screen and (min-width: 1200px) {

    .after-login-main-section-row {
        width: 1180px;
    }

    .gov-card__content {
        min-height: 255px;
    }

    .gov-card__content>div {
        height: 255px;
    }

}