.form__label {
    font-size: .9rem;
    font-weight: 500;
    line-height: 1.331rem;
    letter-spacing: 0.0125em;
    color: #686868;
    /* margin-right: .75rem; */
}

.form__label:nth-child(1) {
    margin-left: 0;
}

.form__switch {
    min-width: 5.75rem;
    height: 1.949rem;
    border-radius: 3px;
    box-sizing: border-box;
}

.form__switch-left, .form__switch-right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid #DDDDDD 1px;
}

.form__switch-right {
    background-color: #2362A2;
    border-radius: 0 3px 3px 0;
    border-right: none;
}
.form__switch-left {
    border-radius: 3px 0 0 3px;
    border-left: none;
}

.form__input {
    width: 100%;
    height: 1.949rem;
    border-radius: 3px;
    border: solid #DDDDDD 1px;
    padding: 5px, 0px, 5px, 0px;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.331rem;
    letter-spacing: 0.0125em;
    text-overflow: ellipsis;
}

.form__input::-moz-placeholder,
.form__input:-ms-input-placeholder,
.form__input::-ms-input-placeholder,
.form__input::-webkit-input-placeholder {
    color: #2362A2 !important;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.331rem;
    letter-spacing: 0.0125em;
}

.form__input::placeholder {
    color: #2362A2 !important;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.331rem;
    letter-spacing: 0.0125em;
}

.form__select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../assets/Chevron-down-dark.svg'); 
    background-repeat: no-repeat;
    background-position: right .5rem center;
    background-size: 1rem;
    border: 1px solid #ccc;
    padding-left: 1rem;
    padding-right: 1.949rem;
    max-width: 100%;
    color: #2362A2;
    height: 1.949rem;
    border-radius: 3px;
    border: solid #DDDDDD 1px;

    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.331rem;
    letter-spacing: 0.0125em;
    text-overflow: ellipsis;
}

.form__select option {
    font-size: .9rem;
}

.form__select::-ms-expand {
    display: none;
}

.input-icon-wrapper {
    /* margin-right: 1.5rem; */
    position: relative;
    display: flex;
    align-items: center;
}

.input-icon {
position: absolute;
left: 10px;
height: 0.9rem;
width: 0.9rem;
width: auto;
pointer-events: none;
}


.gov-tabs__list {
border-bottom: none !important;
}

@media only screen and (min-width: 576px) {
    .form_label-no-width {
        width: 40px !important;
    }
    .form__col-sm-padding {
        padding-left: 1.25rem;
    }
    .form_input{
        max-width: 315px;
    }
    .input-icon-wrapper{
        margin-right: 1.5rem;
    }
    .form__select{
        width: 224px;
    }
}
@media only screen and (min-width: 768px) {
    .form--label-width {
        width: 64px !important;
    }
    .filter-element-visible {
        display: none !important;
    }

    .form__col-sm-padding {
        padding-left: 0;
    }
}

