h2 {
    font-weight: 600;
    font-size: 19px;
    line-height: 28.5px;
    color: #3B3B3B;
}

.table-button-upravit,
.table-button-smazat {
    width: max-content;
    padding: 8px 12px 8px 12px;
    border-radius: 3px;
}

.table-button-upravit {
    background-color: transparent;
    color: #2362A2;
    border: 1px #2362A2 solid;
}

.table-button-smazat {
    background-color: transparent;
    color: #C52A3A;
    border: 1px #C52A3A solid;
}

a {
    text-decoration: none;
}

.add-remove-btn {
    min-width: 23.59375em;
    height: 2.4375em;
    padding: 0;
}

.btn-container {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.document-part {
    max-width: 351px;
}

.element {
    min-width: 153px;
    padding: 0;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 27.5em;
}

.pagination-controls {
    cursor: pointer;
    margin: 0;
    width: 2.5em;
    padding: 0;
}

.pagination-controls img {
    width: 1.25em;
    height: 1.4375em;
    padding: 0;
}

.control-btn.disabled {
    filter: invert(50%);
}

::-webkit-scrollbar {
    height: 8px;
    border-radius: 4px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #EAEAEA;
    border-radius: 4px;
}

textarea {
    resize: none;
    border: 1px #DDDDDD solid;
    width: 100%;
    height: 6.25em;
    border-radius: 0.1875em;
    padding: 0;
}

textarea:focus {
    border: none;
    outline: none;
}

.justification-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.justification-container>span {
    font-weight: 400;
    font-size: 0.90625em;
    line-height: 1.6em;
    margin-bottom: 0.625em;
    letter-spacing: 0.875%;

}

.justification-text {
    width: fit-content;
    font-size: 14.5px;
    line-height: 23.2px;
    font-weight: 400;
}

/* .justification-link {
    width: fit-content;
    font-weight: 400;
    font-size: 0.90625em;
    line-height: 1.6em;
    margin-bottom: 0.625em;
    letter-spacing: 0.875%;
    text-decoration: underline;
} */

.justification-link {
    font-weight: 400;
    font-size: 0.90625em;
    line-height: 1.6em;
    letter-spacing: 0.014em;
    padding: 0 !important;
    color: #2362A2;
    /* border-bottom: 1px #2362A2 solid; */
    text-decoration: underline;
}

.justification-link:hover {
    cursor: pointer;
}

.justification-detail-text>span {
    font-weight: 400;
    font-style: italic;
    font-size: 14.5px;
    line-height: 23.2px;
    margin-bottom: 0.625em;
}

.add-remove-btn>button {
    width: 100%;
}

.full {
    width: 100%;
}

.button-create-req-text {
    margin-left: 10px;
    color: #2362A2;
    font-weight: 700;
    font-size: 13px;
    line-height: 1.75em;
    text-wrap: nowrap;
}

li.page-item {
    margin: 0;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-item::before {
    display: none;
}

.page-link {
    display: flex;
    border: none;
    background-color: transparent !important;
    font-weight: 700;
    font-size: 0.875em;
    line-height: 1.5em;
    margin-bottom: 0.625em;
    letter-spacing: 1.25%;
    color: #2362A2;
    margin: 0;
}


.modal-content {
    padding: 0;
}

.sub-title {
    font-weight: 400;
    font-size: 0.90625em;
    line-height: 1.6em;
    margin-bottom: 0.625em;
    letter-spacing: 0.875%;
    color: #686868;
}

.sub-title-warning {
    font-weight: 500;
    font-size: 0.90625em;
    line-height: 1.6em;
    letter-spacing: 1.125%;
    color: #2362A2;
}

.modal-footer-custom {
    padding-bottom: 2.5em;
}

.button-wrapper {
    cursor: default
}

.button-container {
    height: fit-content;
    cursor: default
}

.modal-background {
    background-color: #F5F5F5;
}

.modal-header--radius {
    border-radius: 3px 3px 0 0;
}

.modal-footer--radius {
    border-radius: 0 0 3px 3px;
}

.modal-help-text--padding-left {
    padding-left: 1.5rem;
    padding-top: 1.5rem;
}

.table-explanation-text-container {
    max-width: 378px;
}

.table-explanation-text {
    font-weight: 400;
    font-size: 0.8125em;
    color: #3B3B3B;
}

.helptext-separate {
    display: block;
    content: "";
    margin-top: 0.6em;
}

.me-12 {
    margin-right: 12px;
}

@media only screen and (max-width: 719px) {

    .modal-footer-custom {
        padding-bottom: 1em;
    }

    .modal-help-text--padding-left {
        padding-left: 0;
        padding-top: 1rem;
    }

    .document-part {
        max-width: 100%;
    }
}

@media only screen and (max-width: 719px) {

    .add-remove-btn {
        min-width: 100%;
        height: 2.4375em;
    }

}


@media only screen and (min-width: 720px) {

    .add-remove-btn {
        min-width: 100%;
        height: 2.4375em;
    }


}