.dokumentace-upload-btn-container {
    width: 100%;
    height: 136px;
    padding: 32px;
    border-radius: 4px;
    border: 1px dashed #DDDDDD;
    background-color: white;
}

.dokumentace-upload-btn-container.hovered {
    background-color: #DDDDDD;
}

.file-drop-target {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dokumentace-upload-btn {
    border: 1px solid #2362A2;
    border-radius: 3px;
    padding: 8px 12px 9px 12px;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #2362A2;
    cursor: pointer;
}

.fst-italic--grey {
    font-size: 17.5px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-align: left;
    color: #686868;

}

.bold {
    font-weight: 600;
    font-size: 1em;
}

.dokumentace-text {
    font-size: 1.09375em;
    line-height: 1.641rem;
}

.dokumentace-text--more {
    font-size: 1.09375em;
    line-height: 1.641rem;
    color: #2362A2;
}

.dokumentace-text--more:hover {
    cursor: pointer;
}

.dokumentace-help_text {
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.422rem;
    letter-spacing: 0.015em;
    color: #2362A2;
}

.dokumentace-instruction_text {
    font-size: 0.813rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.422rem;
    letter-spacing: 0.012em;
    color: #3B3B3B;
}


/* IHOR */
/* .create-link-button {
    max-width: 353.3px;
    height: 48px;
    border: 1px solid #2362A2;
    border-radius: 3px;
    background-color: transparent;
    color: #2362A2;

    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;

    &.disabled {
        color: #B5D9F3;
        border-color: #B5D9F3;
        cursor: default
    }
} */

.link-container {
    max-width: 377.5px;
}

.body-input {
    max-width: 353.3px;
}

.new-construction-button.extend {
    width: 83px;
    border-radius: 0 3px 3px 0;
    padding: 8px 12px 8px 12px;
    font-size: 13px;

    &.disabled {
        color: #B5D9F3;
        border-color: #B5D9F3;
        cursor: default
    }
}

.projektant-help-text {
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
}

.odkaz-stav-container {
    --bs-border-color-processing: #f9c13c;
    --bs-border-color-success: #6FBD2C;

    &.waiting {
        background-color: #ECF7FF;
    }

    &.processing {
        --bs-border-color: var(--bs-border-color-processing);

        background-color: #FEF9EF;
        border-color: var(--bs-border-color);
    }

    &.success {
        --bs-border-color: var(--bs-border-color-success);

        background-color:  #F5FAF0;
        border-color: var(--bs-border-color);
    }
}

.odkaz-stav-header {
    font-weight: 700;
    font-size: 1.125em;
    color: #3B3B3B
}

.odkaz-stav-text {
    font-weight: 400;
    font-size: 0.909rem;
    color: #686868;
}

@media only screen and (max-width: 576px) {

    .body-input,
    .create-link-button,
    .link-container {
        max-width: 100%;
    }
}