.osnova {
    min-height: 200px;
}

.step-title {
    font-size: 28px;
    font-weight: 500;

    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

.step-description {
    font-size: 16px;
}

.permission {
    height: 250px;
    color: #2362A2;
    border: 1px solid #2362A2; 
    border-radius: 3px;
}

@media only screen and (max-width: 768px) {
    .permission {
        height: 47px;
    }

    .oze-button {
        width: 100%;
        margin-bottom: 10px;
    }
}

.add-permission-button {
    border: none;
    background: transparent;

    font-size: 14.5px;
    font-weight: 500;
    line-height: 23.2px;
    letter-spacing: 0.018em;
    color: #2362A2;
}

.add-permission-arrow {
    margin-left: 12px;
}

.zadost-help_text {
    font-size: 1.094rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.641rem;
    letter-spacing: 0.01em;
    color: #686868;
}