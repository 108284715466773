p {
    margin: 0;
}

.rizeni-zadosti,
.rizeni-podani {
    width: 377px;
}

.rizeni-detail-title {
    font-size: 23px;
    font-weight: 500;
    line-height: 34.5px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #3B3B3B;
}

.rizeni-container {
    max-width: 377px;
    max-height: 440px;
    background-color: #FFFFFF;
}

.rizeni-number {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.0125em;
    color: #2362A2;
}

.rizeni-number--table {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.rizeni-row-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #686868;
}

.rizeni-row-number {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.0125em;
}

.rizeni-row-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.rizeni-button {
    width: 100%;
    height: 32px;
    gap: 16px;
    border: none;
    border-radius: 3px;
    background-color: #2362A2;
    color: #FFFFFF;

    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
}

.rizeni-button--table {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
    color: #FFFFFF;

    width: 124px;
    border-radius: 3px;
    background-color: #2362A2;
}

.rizeni-table-head {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #2362A2;
}

/* RIZENI-DETAIL */

.podani-container {
    width: 100%;
    background-color: #FFFFFF;
}

.podani-row {
    font-size: 14.5px;
    font-weight: 400;
    line-height: 23.2px;
    letter-spacing: 0.014em;
    text-align: left;
    color: #686868;
}

.podani-row-info {
    color: #3B3B3B;
}

.podani-row-info--more {
    color: #2362A2;
}

.podani-row-info--more:hover {
    cursor: pointer;
}

.zadost-container {
    width: 377px;
    background-color: #FFFFFF;
}

.zadost-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-align: left;
    color: #3B3B3B;
}

.zadost-date {
    font-size: 14.5px;
    font-weight: 400;
    line-height: 23.2px;
    letter-spacing: 0.014em;
    text-align: left;
    color: #3B3B3B;
}

.zadost-button {
    background-color: transparent;
    width: 165px;
    height: 37px;
    border: 1px solid #2362A2;
    border-radius: 3px;
    color: #2362A2;

    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
}

.zadost-button--filled {
    background-color: #2362A2;
    color: #FFFFFF;
}

.history-container {
    width: 779px;
    background-color: #FFFFFF;
}

.rizeni-history-date {
    width: 88px;
    width: 100%;
    height: 29px;
    border: 1px solid #EAEAEA;
    border-radius: 16px;
    background-color: #FAFAFA;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.rizeni-history-item {
    background-color: #FFFFFF;
}

.rizeni-history-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.rizeni-history-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #686868;
}

.rizeni-history-button {
    width: 111px;
    height: 31px;
    border: 1px solid #2362A2;
    border-radius: 3px;
    background-color: transparent;

    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #2362A2;
}

.rizeni-history-line {
    bottom: 0%;
    left: 50%;
    height: calc(100% - 29px);
    min-height: 1rem;
    width: 1px;
    background-color: #EAEAEA;
}