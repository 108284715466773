.povoleni-subtitle {
    font-size: 21px;
    font-weight: 500;
    line-height: 31.5px;
    letter-spacing: 0.01em;
    text-align: left;
}

.povoleni-subtitle-help-text {
    font-size: 17.5px;
    font-style: italic;
    font-weight: 400;
    line-height: 26.25px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #686868;
}

.povoleni-section-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-align: left;
}

.povoleni-label {
    width: 100%;
    font-size: 14.5px;
    font-weight: 400;
    line-height: 23.2px;
    letter-spacing: 0.014em;
    text-align: left;
    color: #686868;
}

.povoleni-input {
    width: 100%;
    height: 40px;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    padding: 0px 12px;
}

.povoleni-help-text {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 22.75px;
    letter-spacing: 0.012em;
    text-align: left;
    color: #3B3B3B;
}

.povoleni-textarea {
    /* height: 40px; */
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    padding: 0px 12px;
}

.povoleni-additional_text {
    font-size: 0.813rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.422rem;
    letter-spacing: 0.012em;
    color: #3B3B3B;
}