.table-subtitle {
    font-size: 17.5px;
    font-style: italic;
    font-weight: 400;
    line-height: 26.25px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #3B3B3B;
}

.row-number {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.0125em;
}

.row-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.table-head {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #2362A2;
}

.table-head--zadatel {
    font-size: 0.906rem;
    font-weight: 500;
    line-height: 0.906rem;
    letter-spacing: 0.018em;
    color: #686868;
}

.text-info-amount {
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
    margin-bottom: 0.625em;
    letter-spacing: 0.0125em;
}

.detail-button {
    width: 100%;
    height: 40px;
    padding: 8px 4px;
    border: 1px solid #2362A2;
    border-radius: 3px;
    white-space: nowrap;

    font-size: 0.906rem;
    font-weight: 500;
    line-height: 1.45rem;
    letter-spacing: 0.018em;
}

table {
    background-color: white;
    border-radius: 3px;
    table-layout: fixed;
    border-collapse: collapse;
    padding-bottom: 100em;
}

th,
td {
    text-align: left;
}

td {
    word-break: break-word;
}

tbody td:last-child {
    float: none;
}

tbody tr:nth-child(odd) {
    background-color: #fafafa;
}

td p {
    text-wrap: nowrap;
}

.min-width-table {
    min-width: '1100px';
}