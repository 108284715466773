p {
    margin: 0;
}

.dropdown-input-zadosti {
    background: url('../assets/arrowDownBlue.svg') no-repeat right 1.25rem top .6rem;
    appearance: none;
    /* padding: .5rem 1.5rem .5rem .8rem; */
    padding: 0;
    background-color: white;
    border-radius: 3px', border: 'solid 1px #DDDDDD;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.dropdown--items-container {
    padding: .5rem 1.25rem;
    border-radius: 0 0 3px 3px;
}

.dropdown--items-container:last-child {
    margin-bottom: 0;
}

.dropdown--items-container img {
    padding-right: .3rem;
}

.dropdown-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .4rem;
    width: 92%;
    padding: .5rem 1.25rem;
    margin-bottom: 1rem;
}

.dropdown-input-zadosti div:hover {
    background-color: #ecf7ff;;
}

.dropdown-input-zadosti p:active {
    transition: .2s;
    background-color: #d9efff;
}

.attachment--dropdown-container {
    position: relative;
    text-align: left;
    cursor: pointer;
    /* border: none; */
    width: 100%;
}

.attachment--dropdown-container p {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-size: 14.5px;
    font-weight: 500;
}

.attachment--dropdown {
    position: absolute;
    display: flex;
    right: 0;
    left: 0;
    top: 39px;
    flex-direction: column;
    min-width: 60px;
    min-height: 35px;
    border: solid white 1px;
    background-color: white;
    cursor: pointer;
    z-index: 5;
}

/* .attachment--dropdown p {
    padding: .8rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
    color: black;
} */

/* .attachment--dropdown p:hover {
    color: white;
    background-color: #D0D0D0;
    width: 100%;
} */

.zadosti-container {
    background-color: var(--gov-color-neutral-white);
    width: 370px;
}

.dokumentace-card-container {
    background-color: var(--gov-color-neutral-white);
    max-width: 350px;
}

.zadosti-title {
    height: 54px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.0125em;
    color: #2362A2;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.zadosti-id {
    font-size: 14.5px;
    font-weight: 500;
    line-height: 23.2px;
    letter-spacing: 0.018em;

    color: #3B3B3B;
}

.zadosti-row-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #686868;
}

.zadosti-row-number {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.0125em;
}

.zadosti-row-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.zadosti-button {
    width: 100%;
    height: 32px;
    gap: 16px;
    border: none;
    border-radius: 3px;
    background-color: #2362A2;
    color: #FFFFFF;

    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
}

/* TABLE */



.zadosti-table-head {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #2362A2;
}

.zadosti-button--table {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
    color: #FFFFFF;
    width: 124px;
    border-radius: 3px;
    background-color: #2362A2;
}

/* DEVELOPED */

.zadosti-button-dev {
    width: 156px;
    max-width: 50%;
    height: 32px;
    gap: 16px;
    border: none;
    border-radius: 3px;
    background-color: #2362A2;
    color: #FFFFFF;

    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
}

.zadosti-button-dev--delete {
    background-color: transparent;
    border: 1px solid #C52A3A;
    color: #C52A3A;
}

table {
    background-color: white;
    border-radius: 3px;
    table-layout: fixed;
    border-collapse: collapse;
}

th,
td {
    text-align: left;
}
  
  td {
    word-break: break-word;
}

tbody tr:nth-child(odd) {
    background-color: #fafafa;
}