.new-construction-section:hover {
    cursor: pointer;
}

.new-construction-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0125em;
    text-align: left;
    color: #2362A2;
}