.confirmation_text {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #3B3B3B;
}

.loader {
    width: 64px;
    height: 64px;
    background-image: url('../assets/icons/basic/pss-loader-blue.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.submissionInProgress {
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #3B3B3B;
}

.help_text {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #3B3B3B;
}