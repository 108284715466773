.prilohy-title {
    font-size: 1.3125em;
    font-weight: 500;
    line-height: 1.96875em;
    letter-spacing: 0.01em;
    text-align: left;
    color: #2362A2;
    cursor: pointer;
}

.prilohy-second_title {
    font-size: 1.094rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.641rem;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

.prilohy-text {
    font-size: 0.9375em;
    font-weight: 400;
    line-height: 1.4375em;
    letter-spacing: 0.014em;
    color: #686868;
}

.prilohy-row-text {
    padding-right: 2.5em;
    cursor: pointer;
}

.prilohy-subtitle {
    font-size: 1.3125em;
    font-weight: 500;
    line-height: 2em;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

.prilohy-info-text {
    font-size: 0.90625em;
    font-weight: 400;
    line-height: 1.45em;
    letter-spacing: 0.014em;
    color: #686868;
}

.arrow--size {
    width: 20px;
}
