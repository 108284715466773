.kontrola-subtitle {
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

.kontrola-button {
    width: 315px;
    height: 48px;
    border: 1px solid #2362A2;
    border-radius: 3px;
    background-color: transparent;
    color: #2362A2;

    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;

    &.white {
        border: 1px solid #DDDDDD;
        color: #FFFFFF;
        width: 270px;
        padding: 8px 24px 8px 24px;
        font-size: 13px;
        font-weight: 700;
    }

    &.small {
        width: fit-content;
        /* height: 25px; */
        font-size: 15px;
        font-weight: 500;
    }
}

.kontrola-button-proj {
    width: 385px;
    height: 48px;
    border: 1px solid #2362A2;
    border-radius: 3px;
    background-color: transparent;
    color: #2362A2;

    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;
}

.kontrola-button-icon {
    margin-right: 10px;
}

.kontrola-checkbox-input {
    margin-right: 12px;
}

.kontrola-checkbox-input[focused='true'] {
    appearance: none;
    border: solid #C52A3A 1.5px;
    border-radius: 3px;
}

.kontrola-info {
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.015em;
    color: #2362A2;
}

.kontrola-info--warning {
    color: #C78300;
}

.kontrola-section__title {
    font-size: 1.188rem;
    font-weight: 600;
    line-height: 1.781rem;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}