.alert-web-update {
    position: fixed;
    transition: .5s;
    /* top: 92px; */
    left: 0;
    right: 0;
    min-height: 60px;
    width: 100%;
    z-index: 2;
    background-color: white;
    border-bottom: solid #dddddd;
}

.alert-icon {
    filter: invert(90%) sepia(41%) saturate(4811%) hue-rotate(321deg) brightness(96%) contrast(103%);
    margin-right: 8px;
}

.button-wrapper:hover {
    cursor: pointer;
}

.login-card-button {
    background-color: transparent;
    height: 40px;
    border: none;

    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.014em;
    text-align: left;
}

.button-learn-more {
    background-color: white;
    color: #2362A2;
    width: 100%;
    height: 48px;
    padding: 12px, 20px, 13px, 20px;
    border-radius: 3px;
    gap: 20px;
    border: 1px white solid;

    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;
}

.landing-page-header-section {
    background-color: #FFFFFF;
    background-image: url('../assets/bckg_landing_page.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 45%;
    margin-top: 92px;
    height: 85vh;
}


.landing-page-header-first {
    max-width: 444.4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0125em;
    text-align: left;
    color: #3B3B3B;
}

.landing-page-header-second {
    max-width: 444.4px;
    height: 48px;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #3B3B3B;
}

.landing-page-learn-more {
    position: relative;
    background: #2362A2;
    background-image: url('../assets/bckg_learn_more_2.png');
    background-size: 200px auto;
    background-position: bottom;
    background-repeat: no-repeat;
    color: white;
    height: 500px;
}

.landing-page-learn-more--bg {
    background-image: url('../assets/bckg_learn_more.jpg');
    background-size: cover;
    background-position: center;
    background-position-y: 40%;
}

.landing-page-learn-more--bg::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(35, 98, 162, 0.75);
}

.landing-page-learn-more-content {
    z-index: 2;
}

.landing-page-login-row {
    padding: 16px;
}

.landing-page-login-row:hover {
    background-color: #e5ebf0;
    cursor: pointer;
}

.landing-page-login-row p {
    height: 100%;
}

.landing-page-login-forget-pass {
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;

}

.text-underline {
    cursor: pointer;
}

.line-break {
    display: none;
}

.login-card {
    width: 377px;
    height: 240px;
    padding: 48px, 50px, 48px, 50px;
    border-radius: 3px;
    border: 1px solid #dddddd;
    background-color: white;
}

.login-card-title {
    font-size: 21px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #3B3B3B;
}

.next-icon {
    filter: invert(33%) sepia(38%) saturate(1214%) hue-rotate(174deg) brightness(93%) contrast(95%);
}

.p-learn-more-first {
    font-size: 19px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.01em;
    text-align: left;
}

.p-learn-more-second {
    font-size: 25px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: center;
}

.p-learn-more-first-bottom {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0125em;
    text-align: left;
}

@media only screen and (min-width: 576px) {

    .landing-page-header-first,
    .landing-page-header-second {
        padding-right: 0;
        padding-left: 0;
    }

    .button-learn-more {
        width: 277px;
    }
}

@media only screen and (min-width: 700px) {
    .line-break {
        display: inline-block;
    }
}

@media only screen and (min-width: 768px) {
    .landing-page-header-section {
        height: 100vh;
        margin-top: -92px;
    }

    .p-learn-more-second {
        width: 300px;
        font-size: 25px;
        font-weight: 500;
        line-height: 38px;
        letter-spacing: 0.01em;
        text-align: left;
    }

    .landing-page-learn-more {
        height: 259px;
        background-size: 172px;
    }
}

@media only screen and (min-width: 908px) {
    .p-learn-more-second {
        width: 350px;
    }
}
.fullscreen-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 9999;
}

.login-loader {
    width: 72px;
    height: 52px;
}