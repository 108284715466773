.label-zadost {
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.018em;
}

.input {
    width: 100%;
    height: 43px;
    padding-left: 36px;
    border: 1px solid #2362A2;
    border-radius: 3px;

    color: #2362A2;
}

.input::placeholder {
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.014em;

    color: #2362A2;
}

.nova_zadost-switcher:hover {
    cursor: pointer;
    background-color: #D9EFFF;
}

.nova_zadost-title {
    font-size: 23px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #2362A2;
}