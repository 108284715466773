.input-message--error {
    display: none;
}

.id-input2:invalid[focused='true'] {
    border: solid #C52A3A 1px;
}

.font-style--error {
    color: #C52A3A !important;
}

.text-area--validation:invalid[focused='true']{
    border: solid #C52A3A 1px;
}

.id-input2:invalid[focused='true']~.input-message--error {
    display: flex;
    align-items: center;
}

.id-input1:invalid[focused='true']~.input-message--error {
    display: flex;
    align-items: center;
}

.form {
    width: 377px;
}

.zadatel-subtitle {
    font-size: 17.5px;
    font-style: italic;
    font-weight: 400;
    line-height: 26.25px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #686868;
}

.secondary-title {
    font-weight: 500;
    font-size: 21px;
    color: #3B3B3B;
}

.id-title {
    margin-bottom: 24px;
    font-size: 19px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

.id-label {
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.014em;
    color: #686868;
}

.id-input {
    height: 40px;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    padding: 8px 12px;
    margin-top: 4px;
}

.id-input2 {
    width: 100%;
    height: 40px;
    border: 1px solid #DDDDDD;
    background-color: white;
    border-radius: 3px;
    padding: 8px 12px;
    margin-top: 4px;
    text-overflow: ellipsis;

    &.with-ico{
        border-radius: 3px 0 0 3px;
        width: 50%;
    }

    &.with-search {
        padding-left: 2rem;
        background: url('../assets/icon_search.svg') no-repeat left .5rem center;
        background-color: white;
    }

    &.with-loading {
        padding-right: 2.5rem;
        background: url('../assets/icons/basic/pss-loader-blue.svg') no-repeat right .5rem center;
    }

    &.with-loading-and-search {
        padding-left: 2rem;
        padding-right: 2.5rem;
        background-color: white;
        background: url('../assets/icon_search.svg') no-repeat left .5rem center, url('../assets/icons/basic/pss-loader-blue.svg') no-repeat right .5rem center;
    }
}

.id-input2:disabled {
    background-color: #EFEFEF4D;
}

.ico-btn-container{
    width: 100%;
}

.input-help-text {
    /* height: 40px; */
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.012em;
    color: #3B3B3B;
}

.input-help-text-push-down {
    /* height: 40px; */
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.012em;
    color: #3B3B3B;
    position: relative;
    top: 32px
}

.id-checkbox-label {
    width: fit-content;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.id-checkbox-input {
    margin-right: 12px;
}

.add-button {
    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.018em;
    color: #2362A2;
    padding: 12px;
}

.add-button--table {
    min-width: 146px;
    border: 1px solid #2362A2;
    border-radius: 3px;
    background-color: transparent;

    font-size: 15px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.018em;
    color: #2362A2;
    padding: 12px;
}

/* ZADATEL CARD */

.zadatel-card-container {
    width: 100%;
    max-width: 378px;
    min-height: 196px;
    height: 100%;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    background-color: transparent;
}

.zadatel-card-row {
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.014em;
    color: #686868;
}

.zadatel-row-info {
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.014em;
    color: #3B3B3B;
}

.zadatel-button {
    width: 156px;
    max-width: 50%;
    height: 39px;
    gap: 16px;
    border: 1px solid #2362A2;
    border-radius: 3px;
    background-color: transparent;
    color: #2362A2;

    font-size: 13px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.02em;
}

.zadatel-button--table {
    max-width: 124px;
}

.zadatel-button--delete {
    border: 1px solid #C52A3A;
    color: #C52A3A;
}

/* MODAL WINDOW */

.modal-header-custom {
    padding-top: 40px;
    padding-bottom: 40px;
}

.modal-title {
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

.modal-footer-custom {
    padding-bottom: 40px;
}

.button-wrapper {
    width: 100%;
}

.modal-button {
    width: 166px;
    height: 39px;
    gap: 16px;
    border: 1px solid #2362A2;
    border-radius: 3px;
    background-color: transparent;
    color: #2362A2;

    font-size: 13px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.02em;
    cursor: pointer;

    &.disabled-unfilled {
        color: #B5D9F3;
        border-color: #B5D9F3;
        cursor: default
    }
}

.modal-button--filled {
    background-color: #2362A2;
    color: #FFFFFF;
    cursor: pointer;

    &.red {
        background-color: #AF4852;
        border: none;
    }

    &.disabled-filled {
        background-color: #B5D9F3;
        border: none;
        cursor: default
    }
}

@media only screen and (max-width: 575px) {
    .id-input {
        width: 100%;
    }

    .modal-header-custom {
        padding-top: 24px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .modal-body-custom {
        padding-left: 16px;
        padding-right: 16px;
    }

    .modal-footer-custom {
        padding-bottom: 24px;
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media only screen and (max-width: 991px) {
    .modal-button {
        width: 100%;

        &.filled {
            margin-bottom: 0.8em;
        }
    }

    .button-wrapper--inner {
        margin-bottom: 40px;
    }

    .id-input.with-text {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .id-input2.with-ico{
            width: 100%;
        }
}
