.zadosti-detail-header {
    margin-bottom: 40px;
}

.zadosti-detail-button, .zadosti-detail-button--dropdown {
    background-color: transparent;
    border: 1px solid hsl(210, 64%, 39%);
    border-radius: 3px;

    font-size: 14.5px;
    font-weight: 500;
    line-height: 23.2px;
    letter-spacing: 0.018em;
    color: #2362A2;
}

.zadosti-detail-button--dropdown[focused='false']:hover {
    background-color: #ecf7ff;
}

.zadosti-detail-button--dropdown[focused='false']:active {
    transition: .2s;
    background-color: #d9efff;
}

.zadosti-detail-button:hover {
    background-color: #ecf7ff;;
}

.zadosti-detail-button:active {
    transition: .2s;
    background-color: #d9efff;
}

.zadosti-history-date {
    border: 1px solid #3B3B3B;
    border-radius: 3px;
}

.breadcrumbs-section-detail {
    margin-top: 108px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.header__ul li a, .header__li-rizeni-id {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.0125em;
}

.header__li-rizeni-id {
    font-weight: 700;
}

.zadosti-barcode {
    width: 140px;
}

.zadosti-barcode-info {
    display: flex;
    align-items: center;
    justify-content: center;
}

.zadosti-barcode-image {
    width: 140px;
    height: 140px;
    margin-bottom: 8px;
}

.barcode-text {
    font-size: 13px; 
    font-weight: 400;
    color: #2362A2;
    line-height: 15px;
}

.zadosti-icon-info {
    width: 1rem; 
    height: 1rem;
    margin-right: 6px;
}

@media only screen and (max-width: 768px) {
    .zadosti-detail-header {
        background: #F5F5F5;
    }

    .breadcrumbs-section-detail {
        display: none;
    }
}

@media only screen and (min-width: 1040px) {
    .breadcrumbs-section-detail {
        margin-top: 155px;
    }

    .zadoisti-detail-info {
        margin-right: 24px;
    }

    .zadosti-barcode-info {
        text-align: center;
        justify-content: center !important;
        margin-top: .5rem;
    }
}

@media only screen and (max-width: 991.98px) {
    .zadosti-detail-page {
        padding: 0 20px;
    }
}