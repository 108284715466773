.button{
    margin: 16px 0 ;
}

.copy-button{
    transition: .3s ease-in-out;
    margin-left: 4px;
}

.copy-button:hover{
    filter: brightness(0) saturate(100%) invert(27%) sepia(68%) saturate(1266%) hue-rotate(186deg) brightness(95%) contrast(85%);
}

.copy-button-container{
    margin-top: 16px;
    display: flex;
    align-items: center;
}

.copy-button-container .button{
   margin: 0;
   margin-right: 30px;
}

.reset-button-styles{
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    cursor: pointer;
    text-align: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.label-copy-button .element {
    min-width: auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.gov-button .gov-icon__holder {
    color: inherit;
}

.gov-icon .gov-icon__holder {
    color: inherit;
}