.title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;
    color: #3B3B3B;
}

.title--warning {
    color: #C78300;
}

.form-zamer-input {
    position: relative;
    height: 40px;
    padding-left: 36px;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
}

.form-zamer-input-bordered {
    position: relative;
    width: 100%;
    max-width: 377px;
    height: 40px;
    padding-left: 1.5px;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    /* &.missing-field-input {
        border: 1px solid #C52A3A;
    } */
}

.form-zamer-input.waterworks {
    width: 100%;
}

.zamery-list {
    position: absolute;
    top: 100%;
    width: 377px;
    /* padding: 8px; */
    margin: 0;
    border-radius: 3px;
    background-color: #3B3B3B;
    color: #FFFFFF;
}

.zamery-list-item:hover {
    background-color: #686868;
}

.form-zamer-input::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0125em;
    color: #A8A8A8;
}

.help-text {
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.012em;
    color: #686868;
}

.section-title {
    font-size: 21px;
    font-weight: 500;
    line-height: 31.5px;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

.section-description {
    font-size: 17.5px;
    font-style: italic;
    font-weight: 400;
    line-height: 26.25px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #3B3B3B;
}

.warning-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.015em;
    color: #C52A3A;
}

.textarea-input {
    padding-top: 0.5em !important;
    width: 100%;
    font-size: 14.5px;
    font-weight: 400;
    line-height: 23.2px;
    letter-spacing: 0.014em;
    text-align: left;
    color: #686868;
}

.building-change {
    width: 100%;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 22.75px;
    letter-spacing: 0.012em;
    color: #3B3B3B;
}

.select-input {
    width: 100%;
    font-size: 14.5px;
    font-weight: 400;
    line-height: 23.2px;
    letter-spacing: 0.014em;
    color: #686868;
}

.select {
    width: 100%;
    height: 40px;
}

.new-construction-help-text {
    font-size: 14.5px;
    font-weight: 500;
    line-height: 23.2px;
    letter-spacing: 0.014em;
    color: #686868;
}

.existing-zamer-info {
    font-size: 13px;
    font-weight: 400;
    line-height: 22.75px;
    letter-spacing: 0.015em;
    text-align: left;
    color: #2362A2;
}


/* COMMON STYLES TO DIFFERENT COMPONENTS IN ZADOST-POVOLENI-STAVBY */

.row-wrapper {
    margin-bottom: 40px;
}

.form {
    width: 100%;
}

.main-title {
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

.subtitle {
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

.radio {
    margin-right: 12px;
}

.label {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0125em;
    color: #686868;

    &.deletion {
        font-weight: 500;
        font-weight: 18px;
    }
}

.label:has(input[type="radio"]) {
    width: fit-content;
}

.id-checkbox-label {
    display: flex;
    width: fit-content;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.id-checkbox-input {
    margin-right: 12px;
}

.select-prijemci {
    width: 377px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;
    color: #3B3B3B;
}

.checkbox-prijemci {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.new-construction-input {
    width: 100%;
    height: 40px;
    border-left: 1px solid #DDDDDD;
    border-top: 1px solid #DDDDDD;
    border-bottom: 1px solid #DDDDDD;
    border-right: none;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 8px 12px;
}

.new-construction-button {
    width: 64px;
    height: 40px;
    border: 1px solid #2362A2;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    font-size: 13px;
    font-weight: 700;
    line-height: 22.75px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #2362A2;
}

.zamer-section__title {
    font-size: 1.188rem;
    font-weight: 600;
    line-height: 1.781rem;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

@media only screen and (max-width: 575px) {
    .form-zamer-input {
        width: 100%;
    }
}