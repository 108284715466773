.pagination-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.developed-amount {
    background-color: #2362A2;
    color: white;
    padding: 4px 8px 4px 8px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 400;
    margin-left: 0.5em;
}

.gov-tabs__item:nth-of-type(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
}