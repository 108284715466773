@keyframes spinner {
    0% {
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        stroke: currentColor;
        opacity: 0;
    }
    5% {
        opacity: 100;
    }
    25% {
        stroke: currentColor;
    }
    50% {
        stroke-dasharray: 100;
        stroke-dashoffset: 0;
        opacity: 100;
        stroke: currentColor;
    }
    100% {
        opacity: 0;
        stroke: currentColor;
    }
}

@keyframes overlay {
    0% {
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        stroke: #eaeaea;
        opacity: 0;
    }
    20% {
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        stroke: #eaeaea;
        opacity: 0;
    }
    45% {
        opacity: 100;
    }
    75% {
        stroke: #ddd;
    }
    95% {
        stroke-dasharray: 100;
        stroke-dashoffset: 0;
        opacity: 100;
        stroke: #eaeaea;
    }
    100% {
        stroke-dashoffset: 0;
        opacity: 0;
        stroke: #eaeaea;
    }
}
