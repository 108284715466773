.rozsahContainer {
    width: 100%;
}


.title.primary {
    color: #3B3B3B;
    font-weight: 500;
    font-size: 1.75em;
}

.title.secondary {
    color: #3B3B3B;
    font-size: 1.3125em;
    font-weight: 500;
}

.title.third {
    color: #3B3B3B;
    font-size: 1.188em;
    font-weight: 600
}

.text-info-span {
    width: 78%;
}

.title-rozsah-bigger-secondary {
    font-weight: 500;
    font-size: 21px;
    line-height: 31.5px;
}

.title-rozsah-bigger-tertiaty {
    font-weight: 600;
    font-size: 19px;
    line-height: 28.5px;
}

.title-rozsah-bigger-tertiaty-helptext {
    font-weight: 400;
    font-size: 17.5px;
    line-height: 26.25px;
}

span {
    color: #3B3B3B;
    font-size: 0.8125em;
    line-height: 1.421875em;
}

label {
    color: #3B3B3B;
    line-height: 24px;
}

.other-input-label {
    font-weight: 400;
    font-size: 0.90625em;
    line-height: 1.6em;
    margin-bottom: 0.625em;
    letter-spacing: 0.875%;
    color: #686868;
}

.other-input {
    height: 2.5em;
    border-radius: 0.1875em;
    border: 0.0625em solid #DDDDDD;
}

.rozsah-help-text {
    width: 377px;
}