.home-loading-icon .background {
    position: absolute;
    stroke: #eaeaea;
}

.home-loading-icon .spinner {
    position: absolute;
    pointer-events: none;
    animation-timing-function: ease-in-out;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: default;
    animation-name: spinner;
}

.home-loading-icon .overlay {
    position: absolute;
    pointer-events: none;
    animation-timing-function: ease-in-out;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: default;
    animation-name: overlay;
}
