.text.primary {
    font-size: 28px;
    font-weight: 500;
    color: #3B3B3B;
}

.text.secondary {
    font-size: 16px;
    font-weight: 700;
    color: #3B3B3B;
    letter-spacing: 0.015em;
}

.search {
    width: 100%;
    height: 40px;
    padding-left: 30px;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
}

.search::placeholder {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.0125em;
    color: #A8A8A8;
}

span {
    font-size: 13px;
    font-weight: 400;
    color: #686868;
    letter-spacing: 0.012em;

}

/* ===== */

.prijemci-subtitle {
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.01em;
    color: #3B3B3B;
}

.prijemci-list-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-align: left;
    color: #3B3B3B;
}

.prijemci-list-description {
    font-weight: 400;
}

.react-datalist-input__container {
    width: 100%;
}

.react-datalist-input__container.with-loading .react-datalist-input__textbox{
    padding-left: 2rem;
    padding-right: 2.5rem;
    background: url('../assets/icon_search.svg') no-repeat left .5rem center, url('../assets/icons/basic/pss-loader-blue.svg') no-repeat right .5rem center;
    background-color: white;
}

.react-datalist-input__textbox {
    background-image: url(/src/assets/icon_search.svg);
    background-position-x: 0.5rem;
    background-position-y: center;
    background-size: initial;
    background-repeat: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    padding: 0 0 0 2rem;
    width: 100%;
    height: 40px;
    border: 1px solid #DDDDDD;
    border-radius: 3px;

    &::placeholder {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.0125em;
        color: #A8A8A8;
    }

    &.error {
        border: 2px solid #C52A3A;

    }
}

.react-datalist-input__listbox {
    position: relative;
    background-color: white;
    margin: 0;
    padding: 0;
    z-index: 10;
}

.react-datalist-input__listbox-option {
    padding: 0.4em 0.5em 0.4em 0.5em;
    margin: 0;

    &:hover {
        background-color: #DDDDDD;
        cursor: pointer;
    }

    &::before {
        display: none;
    }
}

mark {
    padding: 0;
    background-color: transparent;
}