.stavby-main-container {
    max-width: 1180px;
}

.stavby-container {
    max-width: 377px;
    max-height: 420px;
    background-color: #FFFFFF;
}

.stavby-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.0125em;

    color: #2362A2;
}

.stavby-row-name {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #686868;
}

.stavby-row-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #3B3B3B;
}

.stavby-row-number {
    font-size: 15px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.018em;

    color: #3B3B3B;
}

.stavby-name {
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.011em;
    text-align: left;

    color: #3B3B3B;
}

.stavby-button {
    width: 100%;
    height: 32px;
    gap: 16px;
    border: none;
    border-radius: 3px;
    background-color: #2362A2;
    color: #FFFFFF;

    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
}

/* TABLE */

.stavby-table-head {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.0125em;
    color: #2362A2;
}

.stavby-button--table {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
    color: #FFFFFF;

    width: 124px;
    border-radius: 3px;
    background-color: #2362A2;
}

/* DEVELOPED */

.stavby-button-link {
    width: 156px;
    max-width: 50%;
}

.stavby-button-dev {
    width: 156px;
    height: 32px;
    gap: 16px;
    border: none;
    border-radius: 3px;
    background-color: #2362A2;
    color: #FFFFFF;

    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.20000000298023224px;
}

.stavby-button-dev--delete {
    max-width: 50%;
    background-color: transparent;
    border: 1px solid #C52A3A;
    color: #C52A3A;
}